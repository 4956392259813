import React, { Component } from "react";
import PropTypes from "prop-types";

import { Card, CardBody } from "reactstrap";
import { Table } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import CustomPagination from "components/Common/CustomPagination";
import moment from "moment";
import { displayTransportStatus } from "helpers/utils";
import { Link } from "react-router-dom";

class ReceiveOrderTable extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      itemCount,
      currentPage,
      totalPage,
      defaultPageSize,
      items,
      loading,
      onViewDetail,
      onChangePage,
      onPageSizeChange,
    } = this.props;
    return (
      <Card>
        <CardBody>
          <div className="p-2">
            <CustomPagination
              itemCount={itemCount}
              currentPage={currentPage}
              totalPage={totalPage}
              defaultPageSize={defaultPageSize}
              pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
              onChangePage={onChangePage}
              onPageSizeChange={onPageSizeChange}
            />
          </div>
          <div className="table-rep-plugin">
            {loading ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-primary m-1" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div
                className="table-responsive mb-0"
                data-pattern="priority-columns"
              >
                <Table
                  id="tech-companies-1"
                  className="table table-striped table-bordered"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th style={{ width: "120px" }}>Action</th>
                      <th>Title</th>
                      <th>Date</th>
                      <th>Orders</th>
                      <th>Default Day of ETA</th>
                      <th>Status</th>
                      <th>Created By</th>
                      <th>Updated By</th>
                      <th>Created Date</th>
                      <th>Updated Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item, index) => {
                      const { orderCount } = item;
                      let num =
                        (currentPage - 1) * defaultPageSize + (index + 1);
                      return (
                        <tr
                          key={index}
                          onDoubleClick={() => onViewDetail(item.id)}
                          style={{ cursor: "pointer" }}
                        >
                          <td>{num}</td>
                          <td>
                            <div className="text-center">
                              <Link className="text-primary" to="#">
                                <i
                                  className="far fa-list-alt font-size-18"
                                  onClick={() => onViewDetail(item.id)}
                                ></i>
                              </Link>
                            </div>
                          </td>
                          <td>{item.title}</td>
                          <td>
                            {moment(item.transportDate).format("DD-MMM-yyyy")}
                          </td>
                          <td className="text-end">{orderCount}</td>
                          <td className="text-end">{item.defaultDayOfETA}</td>
                          <td>{displayTransportStatus(item)}</td>
                          <td>{item.createdByUser}</td>
                          <td>{item.updatedByUser}</td>
                          <td>
                            {moment
                              .utc(item.createdDate)
                              .local()
                              .format("DD-MMM-yyyy h:mm:ss a")}
                          </td>
                          <td>
                            {moment
                              .utc(item.updatedDate)
                              .local()
                              .format("DD-MMM-yyyy h:mm:ss a")}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
          <CustomPagination
            itemCount={itemCount}
            currentPage={currentPage}
            totalPage={totalPage}
            defaultPageSize={defaultPageSize}
            pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
            onChangePage={i => onChangePage(i)}
            onPageSizeChange={size => onPageSizeChange(size)}
          />
        </CardBody>
      </Card>
    );
  }
}

ReceiveOrderTable.propTypes = {
  itemCount: PropTypes.number,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onEdit: PropTypes.func,
  onViewDetail: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
};

export default ReceiveOrderTable;
