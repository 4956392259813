import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
  UncontrolledButtonDropdown,
} from "reactstrap";
import { NIL } from "uuid";
import Select from "react-select";
import {
  getMaxValue,
  saleOrderDeliveryStatusOptions,
  saleOrderPaymentStatusOptions,
  saleOrderStatusOptions,
  saleOrderTypeStatusOptions,
  saleOrderPaymentOptions, 
  saleOrderPackingStatusOptions
} from "../../helpers/utils";
import SaleOrderProductRow from "./SaleOrderProductRow";
import moment from "moment/moment";
import { discountPromotionService } from "services/discount-promotion-service";
import ModalForm from "../sale-order-product/ModalForm";
import CreatableUserSelect from "../user/CreatableUserSelect";
import UserSelect from "../user/UserSelect";
import SaleOrderGenerateStockOutResultModal from "./SaleOrderGenerateStockOutResultModal";
import NumberFormat from "react-number-format";
import { saleOrderInvoiceService } from 'services/sale-order-invoice-service';
import SaleOrderAdditionalEntryForm from "./SaleOrderAdditionalEntryForm";
import ProductSelectionDropdown from "../product/ProductSelectionDropdown";
import { getDiscountPromotionProduct } from "store/discount-promotion/saga";
import CreatableAddressSelect from "../address/CreatableAddressSelect";
import CreatableShippingContactSelect from "../shipping-contact/CreatableShippingContactSelect";

const SaleOrderForm = props => {
  const { item, onSubmit, onCancel, result } = props;
  const [disableChange, setDisableChange] = useState(false);
  
  const [ term, setTerm ] = useState("");
  const [ loading, setLoading ] = useState(false);
  const [ isHeaderSelected, setIsHeaderSelected ] = useState(false);
  const [ loadingSender, setLoadingSender ] = useState(false);
  const [ senderTerm, setSenderTerm ] = useState("");
  const [ modalAddSaleOrderProduct, setModalAddSaleOrderProduct ] =
      useState(false);
  const [ discountOptions, setDiscountOptions ] = useState([]);
  const [ modalResult, setModalResult ] = useState(false);
  const [ statusOptions, setStatusOptions ] = useState([]);
  const [ deliveryStatusOptions, setDeliveryStatusOptions ] = useState([]);
  
  const [ saleOrder, setSaleOrder ] = useState({
    id: NIL,
    orderNo: "",
    type: { key: 0, value: 0, label: "Default" },
    paymentMethod: { key: 0, value: 0, label: "Cash" },
    title: "",
    customerId: "",
    sellerId: "",
    discountPromotionId: "",
    status: 0,
    shippingContactId: null,
    addressId: null,
    paymentStatus: 6, // Unpaid
    deliveryStatus: 0,
    packingStatus: 0,
    saleOrderProducts: [],
    saleOrderAdditionalEntries: [
      {
        id: NIL,
        index: 0,
        saleOrderId: null,
        currencyId: NIL,
        title: "",
        amount: "",
      },
    ],
  });

  const [ isSubmitted, setIsSubmitted ] = useState(false);

  useEffect(() => {
    if (result) {
      const { id } = result;
      setSaleOrder({
        ...saleOrder,
        id,
      });

      setModalResult(true);
    }
  }, [ result ]);

  useEffect(() => {
    setLoadingSender(true);

    const fetchDiscountPromotions = discountPromotionService.getDiscountPromotionsRequest({ term: senderTerm });
    const fetchAdditionalDiscounts = getDiscountPromotionProduct({ term: senderTerm });

    Promise.all([fetchDiscountPromotions, fetchAdditionalDiscounts])
      .then(([discountPromotions, discountPromotionPro]) => {
        const combinedDiscountOptions = [
          ...(Array.isArray(discountPromotions) ? discountPromotions : []).map(discountPromotion => ({
            key: discountPromotion.id,
            value: discountPromotion.id,
            label: discountPromotion.name,
          })),
          ...(Array.isArray(discountPromotionPro) ? discountPromotionPro : []).map(discountPromotion => ({
            key: discountPromotion.id,
            value: discountPromotion.id,
            label: discountPromotion.percentage,
          })),
        ];
  
        setDiscountOptions(combinedDiscountOptions);
      })
      .catch(error => {
        console.error("Error fetching discount promotions:", error);
      })
      .finally(() => {
        setLoadingSender(false);
      });
  }, [senderTerm]);

  const populateDeliveryStatusOptions = (deliveryStatus) => {
    switch (deliveryStatus) {
      case 0: // Draft
      case 1: // Pending
        setDeliveryStatusOptions([
          { key: 0, value: 0, label: "Draft" },
          { key: 1, value: 1, label: "Pending" }
        ]);
        break;
      case 3: // On Delivery, 
      case 4: // Delivered,
      case 5: // Completed,
        setDeliveryStatusOptions([
          { key: 3, value: 3, label: "On Delivery" },
          { key: 4, value: 4, label: "Delivered" },
          { key: 5, value: 5, label: "Completed" }
        ]);
        break;
    }
  };
  
  const populateSaleOrderStatusOptions = (status) => {
    switch (status) {
      case 0:
        setStatusOptions([
          { key: 0, value: 0, label: "Draft" },
          { key: 5, value: 5, label: "Confirmed" },
        ])
        break
      case 5:
        setStatusOptions([
          { key: 4, value: 4, label: "Cancelled" },
          { key: 5, value: 5, label: "Confirmed" },
          { key: 6, value: 6, label: "Refund" },
        ])
        break;
      case 6:
        setStatusOptions([
          { key: 6, value: 6, label: "Refund" },
          { key: 7, value: 7, label: "Refund Confirmed" },
        ])
        break;
      default:
        break;
    }
  }
  
  useEffect(() => {
    if (item) {
      const additionalEntries = item.saleOrderAdditionalEntries?.map((a, index) => ({
        ...a,
        index,
      })) || [];
      setDisableChange(item.status === 5);
      populateDeliveryStatusOptions(item.deliveryStatus);
      populateSaleOrderStatusOptions(item.status);
      
      setSaleOrder({
        ...item,
        customerId: item.customerId ? {
          key: item.customerId,
          value: item.customerId,
          label: item.customerName,
          uniqueId: item.customerUniqueId,
        } : null,
        status: {
          key: item.status,
          value: item.status,
          label: item.statusName,
        },
        paymentStatus: {
          key: item.paymentStatus,
          value: item.paymentStatus,
          label: item.paymentStatusName,
        },
        deliveryStatus: {
          key: item.deliveryStatus,
          value: item.deliveryStatus,
          label: item.deliveryStatusName,
        },
        packingStatus: {
          key: item.packingStatus,
          value: item.packingStatus,
          label: item.packingStatusName,
        },
        type: {
          key: item.type,
          value: item.type,
          label: item.typeName,
        },
        paymentMethod: {
          key: item.paymentMethod,
          value: item.paymentMethod,
          label: item.paymentMethodName,
        },
        sellerId: item.sellerId ? {
          key: item.sellerId,
          value: item.sellerId,
          label: item.sellerName,
          uniqueId: item.sellerUniqueId,
        } : null,
        shippingContactId: item.shippingContactId ? {
          key: item.shippingContactId,
          value: item.shippingContactId,
          label: item.shippingContact?.contact,
          phones: item.shippingContact?.phones,
          address: item.shippingContact?.address,
        }: null,
        addressId: item.addressId ? {
          key: item.addressId,
          value: item.addressId,
          label: item.address?.name,
        } : null,
        discountPromotionId: item.discountPromotionId ? {
          key: item.discountPromotionId,
          value: item.discountPromotionId,
          label: item.discount,
        } : null,
        date: moment(item.date).toDate(),
        saleOrderProducts: item.saleOrderProducts?.map((a, index) => ({
          ...a,
          index,
          total: a.quantity * a.price,
          label: `${ a.productCode } - ${ a.productName }`,
          uomId: {
            id: a.productUomId,
            value: a.uomId,
            label: a.productUomTitle,
          },
        })) || [],
        saleOrderAdditionalEntries: additionalEntries.length > 0
            ? additionalEntries
            : [ {
              id: NIL,
              index: 0,
              title: "Delivery Fee",
              currencyId: NIL,
              saleOrderId: null,
              amount: 1.5,
            } ],
      });
    } else {
      setDeliveryStatusOptions([
        { key: 0, value: 0, label: "Draft" },
        { key: 1, value: 1, label: "Pending" },
      ])
      setSaleOrder({
        id: NIL,
        orderNo: "",
        title: "",
        status: saleOrderStatusOptions.find((e) => e.value === 0),
        paymentStatus: saleOrderPaymentStatusOptions.find((e) => e.value === 6), // Unpaid
        deliveryStatus: saleOrderDeliveryStatusOptions.find((e) => e.value === 0),
        packingStatus: saleOrderPackingStatusOptions.find((e) => e.value === 0),
        addressId: null,
        type: { key: 0, value: 0, label: "Default" },
        paymentMethod: { key: 0, value: 0, label: "Cash" },
        saleOrderProducts: [],
        saleOrderAdditionalEntries: [ {
          id: NIL,
          index: 0,
          title: "Delivery Fee",
          currencyId: NIL,
          saleOrderId: null,
          amount: 1.5,
        } ]
      });
    }
  }, [ item ]);
  
  const handleSelectProduct = (product) => {
    let max = 0;
    if (saleOrder.saleOrderProducts.length > 0) {
      max = getMaxValue(
          saleOrder.saleOrderProducts.map(a => {
            return a.index;
          })
      );
      max += 1;
    } else {
      max = max + 1;
    }
    
    let newEntry={
      ...product,
      index: max,
    }

    setSaleOrder({
      ...saleOrder,
      saleOrderProducts: [ ...saleOrder.saleOrderProducts, newEntry ],
    });
  }

  const handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta;
    
    setSaleOrder({
      ...saleOrder,
      [name]: valueType,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSaleOrder({
      ...saleOrder,
      [name]: value
    });
  };

  const handleDeleteSelected = () => {
    setIsHeaderSelected(false);
    
    setSaleOrder({
      ...saleOrder,
      saleOrderProducts: saleOrder.saleOrderProducts.filter(e => !e.isSelected)
    })
  };

  const handleHeaderSelect = e => {
    const { checked } = e.target;
    setIsHeaderSelected(checked);
    
    setSaleOrder({
      ...saleOrder,
      saleOrderProducts: saleOrder.saleOrderProducts.map(a =>{
        return {
          ...a,
          isSelected: checked,
        }
      })
    })
  };

  const handleStockInItemChange = item => {
    setSaleOrder({
      ...saleOrder,
      saleOrderProducts: saleOrder.saleOrderProducts.map(a =>{
        return a.index === item.index ? item: a;
      })
    });
  };

  const handleDeleteStockInItem = item => {
    setSaleOrder({
      ...saleOrder,
      saleOrderProducts: saleOrder.saleOrderProducts.filter(e => e.index !== item.index)
    })
  };

  const isValidOrderAdditionalEntry = item => {
    return item.title && item.amount;
  };

  const handleSubmit = () => {
    setIsSubmitted(true);

    let isValid = saleOrder.customerId && saleOrder.saleOrderProducts.length > 0;
    let hasNull = saleOrder.saleOrderProducts.some(e => !e.uomId || e.quantity === 0);

    let validAdditionalEntries = saleOrder.saleOrderAdditionalEntries.every(isValidOrderAdditionalEntry);

    if (!hasNull && isValid && validAdditionalEntries) {
      let data = {
        ...saleOrder,
        status: saleOrder.status?.value,
        paymentStatus: saleOrder.paymentStatus?.value,
        deliveryStatus: saleOrder.deliveryStatus?.value,
        packingStatus: saleOrder.packingStatus?.value,
        type: saleOrder.type?.value,
        paymentMethod: saleOrder.paymentMethod?.value,
        customerId: saleOrder.customerId?.value,
        shippingContactId: saleOrder.shippingContactId?.value,
        addressId: saleOrder.addressId?.value,
        sellerId: saleOrder.sellerId?.value,
        discountPromotionId: saleOrder.discountPromotionId?.value,
        saleOrderProducts: saleOrder.saleOrderProducts.map(entry => ({
          ...entry,
          productId: entry.productId,
          productUomId: entry.uomId?.id,
          uomId: entry.uomId?.value,
        })),

        saleOrderAdditionalEntries: saleOrder.saleOrderAdditionalEntries.map(entry => ({
          ...entry,
          saleOrderId: entry.saleOrderId || NIL,
          currencyId: entry.currencyId?.value || entry.currencyId,
        }))
      };

      onSubmit(data);
    }
  };

  const handleOnSelected = product => {
    console.log(product);
  };
  
  const handleOnChange = (item) => {
    setSaleOrder({
      ...saleOrder,
      saleOrderAdditionalEntries: saleOrder.saleOrderAdditionalEntries.map(a =>{
        return a.index === item.index ? item: a
      })
    })
  };

  const handleOnAdd = () => {
    let maxIndex = 0;

    if (saleOrder.saleOrderAdditionalEntries.length > 0) {
      maxIndex = getMaxValue(
          saleOrder.saleOrderAdditionalEntries.map(a => {
            return a.index;
          })
      );
      maxIndex += 1;
    } else {
      maxIndex = maxIndex + 1;
    }

    const newItem = {
      id: NIL,
      index: maxIndex,
      title: "",
      currencyId: NIL,
      saleOrderId: null,
      amount: 0,
    };

    setSaleOrder({
      ...saleOrder,
      saleOrderAdditionalEntries: [ ...saleOrder.saleOrderAdditionalEntries, newItem ]
    })
  };

  const handleOnRemove = (item) => {
    setSaleOrder({
      ...saleOrder,
      saleOrderAdditionalEntries: saleOrder.saleOrderAdditionalEntries.filter(e => e.index !== item.index)
    })
  };

  const handleExportToPdf = () => {
    const { saleOrder } = props;
    if (saleOrder) {
      const { id, orderNo } = saleOrder.item;
      if (id && invoiceNo) {
        saleOrderInvoiceService.exportToPdf(id, `${ orderNo }_sale-order.pdf`);
      }
    }
  };
  
  return (
      <>
        <Card className={ "mb-2" }>
          <CardHeader className={ "bg-transparent border-bottom" }>
            <Row>
              <Col>
                <CardTitle>Info</CardTitle>
              </Col>
              <Col className={ "text-end" }>
                <Button
                    outline
                    size="sm"
                    color="primary"
                    onClick={ handleExportToPdf }
                    className="me-1"
                >
                  <i className="fas fa-file-pdf"></i> Export to PDF
                </Button>
                { saleOrder.id !== NIL ? (
                    <UncontrolledButtonDropdown>
                      <DropdownToggle caret color="primary" outline>
                        <i className="mdi mdi-dots-vertical"></i>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem
                            onClick={ () => setModalPrintPreview(true) }
                            className="text-primary"
                        >
                          <i className="mdi mdi-printer me-1"/> Print Preview
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                ) : null }
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md={ 4 }>
                <FormGroup>
                  <Label htmlFor="">Type</Label>
                  <Select
                      name="type"
                      value={ saleOrder.type }
                      onChange={ handleSelectChange }
                      options={ saleOrderTypeStatusOptions }
                      classNamePrefix="select2-selection"
                      isClearable
                  />
                </FormGroup>
              </Col>
              <Col md={ 4 }>
                <FormGroup className="mb-3">
                  <Label htmlFor="">Order No</Label>
                  <Input
                      type="text"
                      name="orderNo"
                      placeholder="Auto Generate"
                      value={ saleOrder.orderNo }
                      readOnly
                  />
                </FormGroup>
              </Col>
              <Col md={ 4 }>
                <FormGroup>
                  <Label htmlFor="">Payment Method</Label>
                  <Select
                      name="paymentMethod"
                      value={ saleOrder.paymentMethod }
                      onChange={ handleSelectChange }
                      options={ saleOrderPaymentOptions }
                      classNamePrefix="select2-selection"
                      isClearable
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor="">Customer</Label>
                  <CreatableUserSelect
                      modalTitle={ "Create new customer" }
                      placeholder={ "Select customer" }
                      name="customerId"
                      showPhoneNumber
                      roleNames={ [ "Customer" ] }
                      value={ saleOrder.customerId }
                      onChange={ handleSelectChange }
                      isDisabled={disableChange}
                  />
                </FormGroup>
                <div
                    className={
                      isSubmitted && !saleOrder.customerId ? "is-invalid" : ""
                    }
                ></div>

                { isSubmitted && !saleOrder.customerId && (
                    <div className="invalid-feedback-custom">
                      Customer is required.
                    </div>
                ) }
                <FormGroup className="mb-3">
                  <Label htmlFor="">Delivery Address</Label>
                  <CreatableShippingContactSelect
                      name="shippingContactId"
                      userId={saleOrder.customerId?.value}
                      placeholder={ "Select Address" }
                      value={saleOrder.shippingContactId }
                      onChange={ handleSelectChange }
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label htmlFor="">Seller</Label>
                  <UserSelect
                      name="sellerId"
                      value={ saleOrder.sellerId }
                      roleNames={ [
                        "Seller",
                      ] }
                      onChange={ handleSelectChange }
                      isDisabled = {disableChange}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label htmlFor="">Discount Promotion</Label>
                  <Select
                      name="discountPromotionId"
                      value={ saleOrder.discountPromotionId }
                      onChange={ handleSelectChange }
                      options={ discountOptions }
                      classNamePrefix="select2-selection"
                      isLoading={ loadingSender }
                      isClearable
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card className="mb-2">
          <CardHeader className="pb-1 bg-transparent border-bottom">
            <CardTitle>Statuses</CardTitle>
          </CardHeader>
          <CardBody>
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor="">Status</Label>
                  <Select
                      name="status"
                      value={ saleOrder.status }
                      onChange={ handleSelectChange }
                      options={ statusOptions }
                      classNamePrefix="select2-selection"
                      // isDisabled={[1, 2,3, 4].includes(saleOrder.status?.value)}
                      isClearable
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label htmlFor="">Payment Status</Label>
                  <Select
                      name="paymentStatus"
                      value={ saleOrder.paymentStatus }
                      onChange={ handleSelectChange }
                      options={ saleOrderPaymentStatusOptions }
                      classNamePrefix="select2-selection"
                      isDisabled
                      isClearable
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Packing Status</Label>
                  <Select
                    name="packingStatus"
                    value={ saleOrder.packingStatus }
                    onChange={ handleSelectChange }
                    options={ saleOrderPackingStatusOptions }
                    classNamePrefix="select2-selection"
                    isClearable
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Delivery Status</Label>
                  <Select
                      name="deliveryStatus"
                      value={ saleOrder.deliveryStatus }
                      onChange={ handleSelectChange }
                      options={ deliveryStatusOptions }
                      classNamePrefix="select2-selection"
                      isClearable
                      isDisabled={[2].includes(saleOrder.deliveryStatus?.value)} 
                      // 2: Awaiting Delivery
                    
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card className={ "mb-2" }>
          <CardHeader className={ "bg-transparent border-bottom" }>
            <Row>
              <Col md={ 2 }>
                <CardTitle className={ "pt-2" }>Items</CardTitle>
              </Col>
              <Col className={ "text-end" }></Col>
            </Row>
          </CardHeader>
          <CardHeader className={ "bg-transparent border-bottom" }>
            <Row>
              <Col>
                <Label className={ "mt-2" }>Find Items:</Label>
              </Col>
              <Col md={ 8 }>
                <ProductSelectionDropdown
                    onSelect={handleSelectProduct}
                    isDisabled = {disableChange}
                />
              </Col>
              <Col>
                <div className="text-sm-end">
                  <Button
                      color={ "danger" }
                      onClick={ handleDeleteSelected }
                      outline
                      disabled={
                        !saleOrder.saleOrderProducts.some(e => e.isSelected)
                      }
                  >
                    <i className="fas fa-trash me-1"/> Delete Selected
                  </Button>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Table
                id="tech-companies-1"
                className="table-editable table table-striped table-bordered table-nowrap"
            >
              <thead className={ "bg-primary text-white" }>
              <tr>
                <th className={ "text-center" } style={ { width: "80px" } }>
                  <input
                      type="checkbox"
                      className="form-check-input"
                      id="headerSelected"
                      checked={ isHeaderSelected }
                      onChange={ handleHeaderSelect }
                  />
                </th>
                <th className={ "text-center" } style={ { width: "120px" } }>
                  Image
                </th>
                <th className="text-center">Item</th>
                <th className={ "text-center" } style={ { width: "250px" } }>
                  Price
                </th>
                <th className={ "text-center" } style={ { width: "250px" } }>
                  Quantity
                </th>
                <th className={ "text-center" } style={ { width: "250px" } }>
                  Uom
                </th>
                <th className={ "text-center" } style={ { width: "250px" } }>
                  Discount (%)
                </th>
                <th className={ "text-center" } style={ { width: "250px" } }>
                  Total
                </th>
                <th className={ "text-center" } style={ { width: "120px" } }>
                  Action
                </th>
              </tr>
              </thead>
              <tbody>
              { saleOrder.saleOrderProducts.map((entry, index) => (
                  <SaleOrderProductRow
                      key={ index }
                      item={ entry }
                      isSubmitted={ isSubmitted }
                      onChange={ handleStockInItemChange }
                      onDelete={ handleDeleteStockInItem }
                      isDisabled = {disableChange }
                  />
              )) }
              </tbody>
            </Table>
            <div
                className={
                  isSubmitted && saleOrder.saleOrderProducts.length === 0
                      ? "is-invalid"
                      : ""
                }
            ></div>
            { isSubmitted && saleOrder.saleOrderProducts.length === 0 && (
                <div className="invalid-feedback-custom">There are no products</div>
            ) }
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <CardTitle>Additional Services</CardTitle>
            { saleOrder.saleOrderAdditionalEntries &&
                saleOrder.saleOrderAdditionalEntries.map((a, index) => {
                  return (
                      <SaleOrderAdditionalEntryForm
                          key={ index }
                          item={ a }
                          index={ index }
                          isPrimary={ index === 0 }
                          onAdd={ handleOnAdd }
                          onRemove={ handleOnRemove }
                          onChange={ handleOnChange }
                          isDisabled = {disableChange}
                      />
                  );
                }) }
          </CardBody>
        </Card>
        <Card className="mb-2">
          <CardHeader className="pb-1 bg-transparent border-bottom">
            <CardTitle>Summary</CardTitle>
          </CardHeader>
          <CardBody>
            <table className="table-nowrap table table-bordered">
              <tbody>
              <tr>
                <td className="border-0 text-end">Total Amount</td>
                <td className="text-sm-end" style={ { width: '180px' } }>
                  <NumberFormat
                      name="total"
                      value={ saleOrder.saleOrderProducts.reduce((sum, item) => sum + (item.total || 0), 0).toFixed(2) }
                      displayType="text"
                      thousandSeparator={ true }
                      fixedDecimalScale={ true }
                  />
                </td>
              </tr>
              <tr>
                <td className="border-0 text-end">Discount</td>
                <td className="text-end">
                   <NumberFormat
                      value={ (saleOrder.discountPromotionId?.discount || 0).toFixed(2) }
                      displayType="text"
                      thousandSeparator={ true }
                      fixedDecimalScale={ true }
                  /> 
                </td>
              </tr>
              <tr>
                <td className="border-0 text-end">Total Fee</td>
                <td className="text-end">
                  <NumberFormat
                      value={ (() => {
                        const total = saleOrder.saleOrderAdditionalEntries?.reduce((sum, item) => sum + (Number(item.amount) || 0), 0) || 0;
                        return total.toFixed(2);
                      })() }
                      displayType="text"
                      thousandSeparator={ true }
                      fixedDecimalScale={ true }
                  />
                </td>
              </tr>
              <tr>
                <td className="border-0 text-end"><strong>Grand Total</strong></td>
                <td className="text-end">
                  <NumberFormat
                      value={ (() => {
                        const totalAmount = saleOrder.saleOrderProducts.reduce((sum, item) => sum + (item.total || 0), 0);
                        const totalFee = saleOrder.saleOrderAdditionalEntries?.reduce((sum, item) => sum + (Number(item.amount) || 0), 0) || 0;
                       // const discount = saleOrder.discountPromotionId?.discount || 0;
                        const discount = (() => {
                          if (saleOrder.discountPromotionId) {
                            const discountValue = saleOrder.discountPromotionId.discount || 0;
                            const isPercentage = saleOrder.discountPromotionId.isPercentage;
                            return isPercentage ? (totalAmount * discountValue) / 100 : discountValue;
                          }
                          return 0;
                        })();
                
                        const grandTotal = totalAmount + totalFee - discount;
                        return grandTotal.toFixed(2);
                      })() }
                      displayType="text"
                      thousandSeparator={ true }
                      fixedDecimalScale={ true }
                  />
                </td>
              </tr>
              </tbody>
            </table>
          </CardBody>
        </Card>

        <Card className={ "mb-2" }>
          <CardBody>
              <Button
                  color="primary" className={ "me-1" }
                      onClick={ () => handleSubmit() }
                      type="submit">
                Submit
              </Button>
            <Button color="secondary" onClick={ () => onCancel() } type="button">
              Cancel
            </Button>
          </CardBody>
        </Card>
        { result && (
            <SaleOrderGenerateStockOutResultModal
                title={ "Result Validation" }
                isOpen={ modalResult }
                result={ result }
                toggle={ () => setModalResult(false) }
            />
        ) }
        <ModalForm
            isOpen={ modalAddSaleOrderProduct }
            toggle={ () => setModalAddSaleOrderProduct(false) }
            title={ "Select Product" }
            onSelected={ handleOnSelected }
        />
      </>
  );
};

SaleOrderForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  item: PropTypes.object,
};

export default SaleOrderForm;
